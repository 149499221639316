import { CottageMarkdown } from 'components/Common/CottageMarkdown/style';
import { CottagePhoneInput } from 'components/Common/CottagePhoneInput/style';

const inputSelectStyles = {
  variants: {
    outline: {
      field: {
        _focus: {
          borderColor: 'cottage-green.400',
          boxShadow: '0 0 0 1px #3D7158',
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        w: 'full',
      },
    },
  },
};

const components = {
  FormLabel: {
    baseStyle: {
      fontWeight: 500,
      textStyle: 'md',
      color: 'cottage-dark-green.500',
      px: '2',
    },
  },
  CloseButton: {
    baseStyle: {
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
  Table: {
    baseStyle: {
      thead: {
        th: {
          color: 'cottage-dark-green.500',
          textTransform: 'capitalize',
          fontSize: 'sm',
          letterSpacing: 'wider',
          fontWeight: 'semibold',
          pb: 2,
          px: 2,
        },
      },
      td: {
        py: 5,
        fontSize: 'md',
        color: 'cottage-gray.500',
        px: 2,
      },
    },
    variants: {
      noXPadding: {
        td: {
          px: 0,
        },
        thead: {
          th: {
            px: 0,
          },
        },
      },
    },
    sizes: {
      small: {
        td: {
          fontSize: 'sm',
        },
      },
      default: {
        td: {
          fontSize: 'md',
        },
      },
      billTable: {
        thead: {
          th: {
            fontSize: 'md',
            letterSpacing: 0.2,
            pb: '1',
            fontWeight: '600',
          },
        },
      },
    },
    defaultProps: {
      size: 'default',
    },
  },

  Input: {
    ...inputSelectStyles,
  },
  Select: {
    ...inputSelectStyles,
  },
  NumberInput: {
    ...inputSelectStyles,
  },
  Textarea: {
    baseStyle: {
      // borderColor: 'red !important',
      // borderWidth: '1px !important',
      // boxShadow: 'none !important',
      _focus: {
        borderColor: 'cottage-green.500 !important',
        boxShadow: 'none !important',
        borderWidth: '2px !important',
      },
    },
  },
  Checkbox: {
    baseStyle: {
      control: {
        _focus: {
          ring: 2,
          ringColor: 'cottage-green.300',
          boxShadow: 'none',
        },
      },
    },
    variants: {
      primary: {
        control: {
          borderColor: 'cottage-green.500',
          ringColor: '',
        },
      },
    },
  },
  Button: {
    baseStyle: {
      _focus: {
        boxShadow: 'none',
      },
    },
    variants: {
      lighter: {
        lineHeight: '24px',
        fontSize: '14px',
        bg: '#F2F7F2',
        p: '14px 24px',
        color: '#235C48',
        height: '51px',
        fontWeight: 'semibold',
        _hover: {
          bg: '#DEEDDE',
        },
      },
      'lighter-sm': {
        fontSize: 'md',

        bg: '#E3EDE9',
        color: '#235C48',
        fontWeight: 'semibold',
        _hover: {
          bg: '#daefe6',
        },
      },
      'light-dark-green': {
        fontSize: 'md',
        bg: 'cottage-green-50.500',
        color: '#235C48',
        fontWeight: 'semibold',
        _hover: {
          bg: '#daefe6',
        },
      },
    },
  },
  Switch: {
    baseStyle: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  CottageMarkdown,
  CottagePhoneInput,
};

export default components;
