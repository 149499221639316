import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';
import '../styles/global.css';

import { ChakraProvider } from '@chakra-ui/react';
import IsAuthPageChecker from 'components/Auth/IsAuthPageChecker';
import { GAnalytics } from 'components/GoogleAnalytics';
import { AmplifyProvider } from 'containers/Amplify';
import { FrontendMonitoringProvider } from 'containers/FrontendMonitoring';
import AppContextProvider from 'context/AppContext';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import theme from 'theme';

const MyApp = ({ Component, pageProps }: AppProps) => {
  // When the app is deployed on s3, this will rewrite the url.
  // e.g., the url path `/business/idealeatery/products` doesn't map directly
  // to a file on s3, so the error handler takes you to index.html. This
  // snippet should redirect you to the right page.
  // https://stackoverflow.com/a/71975019
  const router = useRouter();
  const path = (/#!(\/.*)$/.exec(router.asPath) || [])[1];
  if (path) {
    router.replace(path);
  }

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  return (
    <FrontendMonitoringProvider>
      <NextNProgress
        color="#235C48"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        showOnShallow={true}
      />
      <AmplifyProvider cognito={pageProps.cognito}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <GAnalytics />
            <AppContextProvider>
              <ChakraProvider theme={theme}>
                <Head>
                  <title>Cottage Dashboard</title>
                </Head>
                <IsAuthPageChecker>
                  <Component {...pageProps} />
                </IsAuthPageChecker>
              </ChakraProvider>
            </AppContextProvider>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AmplifyProvider>
    </FrontendMonitoringProvider>
  );
};

export default MyApp;
