import { Amplify, Auth } from 'aws-amplify';
import { getLogger } from 'utils/logging';

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region: process.env.NEXT_PUBLIC_REGION,
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
      identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.NEXT_PUBLIC_APP_CLIENT_ID,
    },
    Storage: {
      AWSS3: {
        bucket: process.env.NEXT_PUBLIC_ASSETS_BUCKET_NAME,
        region: process.env.NEXT_PUBLIC_REGION,
      },
    },
    API: {
      graphql_endpoint_iam_region: process.env.NEXT_PUBLIC_REGION,
      graphql_endpoint:
        process.env.NEXT_PUBLIC_STAGE === 'local'
          ? 'http://localhost:4001/graphql'
          : `${process.env.NEXT_PUBLIC_API_ENDPOINT}/graphql`,
      graphql_headers: async () => {
        const log = getLogger('graphql_headers');

        const result: Record<string, string> = {};

        try {
          await Auth.currentAuthenticatedUser();
        } catch (e) {
          log.warn('Not authenticated. Skipping token header.');
          return result;
        }

        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        result['X-Cottage-Token'] = idToken;
        return result;
      },
    },
  });
};
