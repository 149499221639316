import { PageRoutes } from 'constants/Routes';
import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';

import AuthHandler from './AuthHandler';

interface IIsAuthPageCheckerProps {
  children: ReactNode;
}

const IsAuthPageChecker: FC<IIsAuthPageCheckerProps> = ({ children }) => {
  const router = useRouter();
  const skipAuth = [
    PageRoutes.SIGN_IN,
    PageRoutes.SIGN_UP,
    PageRoutes.FORGOT_PASSWORD,
    PageRoutes.RESET_PASSWORD,
  ].includes(router.pathname as PageRoutes);

  if (skipAuth) return <>{children}</>;
  return <AuthHandler>{children}</AuthHandler>;
};
export default IsAuthPageChecker;
