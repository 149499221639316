import { changePassword, getUserCredentials, signOut } from 'auth';
import { IChangePasswordInput } from 'components/Auth/ChangePasswordForm/ChangePasswordFields';

export const signOutFn = async () => {
  return await signOut();
};

export const getUserCredentialsFn = async () => {
  return await getUserCredentials();
};

export const changePasswordFn = async (input: IChangePasswordInput) => {
  const { currentUser, oldPassword, newPassword } = input;
  await changePassword(currentUser, oldPassword, newPassword);
};
