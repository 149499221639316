import { GraphQLResult } from '@aws-amplify/api';
import {
  ByIdInput,
  GetProducerAccountQuery,
  GetProducerAndBusinessQuery,
  GetProducerCreditQuery,
  GetProducerInvoiceHistoryQuery,
  GetProducerQuery,
} from 'API';
import {
  createProducerFn,
  getProducerAccountFn,
  getProducerBusinessFn,
  getProducerCreditFn,
  getProducerFn,
  getProducerInvoiceHistoryFn,
  updateProducerFn,
} from 'api/query-functions/producer';
import { QueryKeys } from 'models/react-query';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

export const useGetProducer = (
  input: ByIdInput,
  options: UseQueryOptions<GraphQLResult<GetProducerQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetProducerQuery>>({
    queryKey: [QueryKeys.PRODUCER, input],
    queryFn: getProducerFn,
    ...options,
  });
};

export const useGetProducerBusiness = (
  input: ByIdInput,
  options: UseQueryOptions<GraphQLResult<GetProducerAndBusinessQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetProducerAndBusinessQuery>>({
    queryKey: [QueryKeys.PRODUCER_BUSINESS, input],
    queryFn: getProducerBusinessFn,
    ...options,
  });
};

export const useGetProducerAccount = (
  input: ByIdInput,
  options: UseQueryOptions<GraphQLResult<GetProducerAccountQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetProducerAccountQuery>>({
    queryKey: [QueryKeys.PRODUCER_ACCOUNT, input],
    queryFn: getProducerAccountFn,
    ...options,
  });
};

export const useGetProducerInvoiceHistory = (
  input: ByIdInput,
  options: UseQueryOptions<GraphQLResult<GetProducerInvoiceHistoryQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetProducerInvoiceHistoryQuery>>({
    queryKey: [QueryKeys.PRODUCER_INVOICE_HISTORY, input],
    queryFn: getProducerInvoiceHistoryFn,
    ...options,
  });
};

export const useGetProducerCredit = (
  input: ByIdInput,
  options: UseQueryOptions<GraphQLResult<GetProducerCreditQuery>> = {}
) => {
  return useQuery<GraphQLResult<GetProducerCreditQuery>>({
    queryKey: [QueryKeys.PRODUCER_CREDIT, input],
    queryFn: getProducerCreditFn,
    ...options,
  });
};

export const useCreateProducer = () => {
  const queryClient = useQueryClient();
  return useMutation(createProducerFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.PRODUCER);
      queryClient.invalidateQueries(QueryKeys.PRODUCER_BUSINESS);
    },
  });
};

export const useUpdateProducer = () => {
  const queryClient = useQueryClient();
  return useMutation(updateProducerFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.PRODUCER_ACCOUNT);
    },
  });
};
