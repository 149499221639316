import { defineStyleConfig, StyleConfig } from '@chakra-ui/react';

export const CottagePhoneInput: StyleConfig = defineStyleConfig({
  baseStyle: {
    borderRadius: '0.375rem',
    padding: '1rem 0.375rem',
    paddingLeft: '1rem',
    width: '100%',
    fontSize: '0.875rem',
    height: '2.5rem',
    border: '1px solid',
    borderColor: 'gray.200',
    _focusVisible: {
      outline: 'cottage-green.400 auto 1px',
    },
  },
  sizes: {},
  variants: {
    error: {
      borderColor: 'red',
    },
  },
  defaultProps: {},
});
