export const fonts = {
  heading: 'Inter, sans-serif',
  body: 'Inter, sans-serif',
};

export const fontSizes = {
  xs: '0.656rem', // 10.5px
  sm: '0.75rem', // 12px
  md: '0.875rem', // 14px
  lg: '1.094rem', // 17.5px
  xl: '1.75rem', // 28px
};

export const textStyles = {
  xl: {
    fontSize: { base: '1.5rem', md: 'xl' },
    lineHeight: '1.1em',
  },
  lg: {
    fontSize: { base: 'lg' },
    lineHeight: '1.15em',
    letterSpacing: '0.01em',
  },
  md: {
    fontSize: { base: 'md' },
    lineHeight: '1.15em',
    letterSpacing: '0.01em',
  },
  sm: {
    fontSize: { base: 'sm' },
    lineHeight: '1.2em',
    letterSpacing: '0em',
  },
  xs: {
    fontSize: { base: 'xs' },
    lineHeight: '1.3em',
    letterSpacing: '0em',
  },
};
