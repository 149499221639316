import { createContext, useContext } from 'react';

type Metadata = Record<string, string | boolean | number>;

interface IFrontendMonitoringContext {
  identifyUser: (identifier: string, metadata?: Metadata) => void;
  trackEvent: (event: string, metadata?: Metadata) => void;
}

export const FrontendMonitoringContext = createContext<IFrontendMonitoringContext | undefined>(
  undefined
);

export function useFrontendMonitoring(): IFrontendMonitoringContext {
  const context = useContext(FrontendMonitoringContext);
  if (!context) {
    throw new Error('useFrontendMonitoring must be used within a FrontendMonitoringProvider');
  }
  return context;
}
