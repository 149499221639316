import { HubCallback, HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import { ICredentials } from '@aws-amplify/core/lib-esm/types';
import { CognitoIdentity } from 'API';
import { configureAmplify, CurrentUserInfo, getCurrentUserInfo, getUserCredentials } from 'auth';
import { Hub } from 'aws-amplify';
import { useFrontendMonitoring } from 'containers/FrontendMonitoring';
import { FC, PropsWithChildren, useEffect } from 'react';

interface AmplifyProviderProps extends PropsWithChildren {
  cognito?: CognitoIdentity;
}

export const AmplifyProvider: FC<AmplifyProviderProps> = ({ cognito, children }) => {
  const { identifyUser } = useFrontendMonitoring();

  // This code runs on every render, even for /403, /404, and /500. In those cases, cognito is undefined.
  useEffect(() => {
    configureAmplify();

    const associateSession = (currentUser: CurrentUserInfo) => {
      identifyUser(currentUser.attributes.email, {
        id: currentUser.attributes.sub,
        email: currentUser.attributes.email,
      });
    };

    const disassociateSession = ({ identityId }: ICredentials) => {
      identifyUser(identityId);
    };

    return listenForAuthChanges(associateSession, disassociateSession);
  }, [JSON.stringify(cognito)]);

  return <>{children}</>;
};

const listenForAuthChanges = (
  associateSession: (_: CurrentUserInfo) => void,
  disassociateSession: (_: ICredentials) => void
) => {
  const listener: HubCallback = (data: HubCapsule) => {
    switch (data.payload.event) {
      case 'signIn':
      case 'autoSignIn':
        associateSession(data.payload.data as CurrentUserInfo);
        break;
      case 'signOut':
        getUserCredentials()
          .then(disassociateSession)
          .catch(() => {});
        break;
      default:
        break;
    }
  };

  getCurrentUserInfo()
    .then(associateSession)
    .catch(() => {});

  return Hub.listen('auth', listener);
};
