import { ICredentials } from '@aws-amplify/core';
import { QueryKeys } from 'models/react-query';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';

import { changePasswordFn, getUserCredentialsFn, signOutFn } from '../query-functions/auth';

export const useSignOut = () => {
  return useMutation(signOutFn);
};

export const useGetUserCredentials = (options: UseQueryOptions<ICredentials>) => {
  return useQuery<ICredentials>(QueryKeys.AUTH_USER_CREDS, getUserCredentialsFn, {
    ...options,
  });
};

export const useChangePassword = () => {
  return useMutation(changePasswordFn);
};
