import { useGetUserCredentials } from 'api/query-hooks/auth';
import { useGetProducerBusiness } from 'api/query-hooks/producer';
import { getCurrentAuthenticatedUser, getCurrentUserInfo } from 'auth';
import { PageRoutes } from 'constants/Routes';
import FullScreenSpinner from 'containers/Dashboard/Common/FullScreenSpinner';
import { AppContext } from 'context/AppContext';
import { useRouter } from 'next/router';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { getLogger } from 'utils/logging';

interface IAuthHandlerProps {
  children: ReactNode;
}

const AuthHandler: FC<IAuthHandlerProps> = ({ children }) => {
  const appContext = useContext(AppContext);
  const [producerId, setProducerId] = useState<string>('');
  const router = useRouter();

  const getUserCredentials = useGetUserCredentials({
    enabled: false,
  });

  const getProducerBusinessQuery = useGetProducerBusiness(
    { id: producerId },
    {
      enabled: !!producerId,
      retry: false,
      onSettled: (data, error) => {
        if (!data?.data?.producer || error) {
          // not in the user table, send them to sign in
          // Todo: Need to fix redirect in signup Page : bug produced during migration
          router.push(PageRoutes.SIGN_IN);
          return;
        }

        const producer = data.data.producer;
        if (producer.id !== appContext.producerId) {
          appContext.setProducerId(producer.id);
        }

        if (producer.business?.id) {
          if (producer.business.id !== appContext.businessId) {
            appContext.setBusinessId(producer.business.id);
          }

          if (producer.business.metadata.title !== appContext.businessTitle) {
            appContext.setBusinessTitle(producer.business.metadata.title);
            appContext.setBusinessSubdomain(producer?.business.subdomain);
            appContext.setBusinessLevel(producer?.business.level);
          }
        } else {
          // they don't have a business yet, send them to onboarding
          router.push(PageRoutes.ONBOARDING);
          return;
        }
      },
    }
  );

  // Very first step of the app is conducted here, talk to cognito.
  useEffect(() => {
    const log = getLogger('AuthHandler.useEffect');

    const fetchCreds = async () => {
      // Check if user is logged in
      try {
        await getCurrentAuthenticatedUser();
      } catch (e) {
        // Not logged in
        log.debug('Not authenticated with Amplify. Redirecting to sign-in page.');
        router.push(PageRoutes.SIGN_IN);
        return;
      }

      // Get logged-in user's producer id
      const currentUser = await getCurrentUserInfo();
      const id = currentUser.attributes.sub;
      log.debug(`Logged in as ${id}`);

      // Obtain AWS signing credentials from Cognito identity pool
      await getUserCredentials.refetch();

      if (id && id !== appContext.producerId) {
        setProducerId(id);
      }
    };

    fetchCreds()
      .then(() => log.debug('Got credentials'))
      .catch((e) => log.error('Failed to get credentials', e));
  }, []);

  if (getProducerBusinessQuery.isLoading) {
    return <FullScreenSpinner />;
  }

  const producer = getProducerBusinessQuery.data?.data?.producer;

  if (!producer || !!appContext.producerId) {
    return <>{children}</>;
  }

  if (!producer.business?.subdomain) {
    router.replace(PageRoutes.ONBOARDING);
    return null;
  }
  return null;
};
export default AuthHandler;
