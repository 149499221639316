import {
  ByIdInput,
  CreateProducerInput,
  CreateProducerMutation,
  GetProducerAccountQuery,
  GetProducerAndBusinessQuery,
  GetProducerCreditQuery,
  GetProducerInvoiceHistoryQuery,
  GetProducerQuery,
  UpdateProducerInput,
  UpdateProducerMutation,
} from 'API';
import {
  createProducerMutationGql,
  getProducerAccountQueryGql,
  getProducerBusinessQueryGql,
  getProducerCreditQueryGql,
  getProducerInvoiceHistoryQueryGql,
  getProducerQueryGql,
  updateProducerMutationGql,
} from 'api/graphql/producer';
import { API } from 'aws-amplify';
import { QueryFnProps } from 'models/react-query';

import { executeQuery } from '../helpers/executeQuery';

export const getProducerFn = async ({ queryKey }: QueryFnProps<ByIdInput>) => {
  const [, input] = queryKey;
  return await executeQuery<GetProducerQuery>(API, getProducerQueryGql, {
    input,
  });
};

export const getProducerBusinessFn = async ({ queryKey }: QueryFnProps<ByIdInput>) => {
  const [, input] = queryKey;
  return await executeQuery<GetProducerAndBusinessQuery>(API, getProducerBusinessQueryGql, {
    input,
  });
};

export const getProducerAccountFn = async ({ queryKey }: QueryFnProps<ByIdInput>) => {
  const [, input] = queryKey;
  return await executeQuery<GetProducerAccountQuery>(API, getProducerAccountQueryGql, {
    input,
  });
};

export const getProducerInvoiceHistoryFn = async ({ queryKey }: QueryFnProps<ByIdInput>) => {
  const [, input] = queryKey;
  return await executeQuery<GetProducerInvoiceHistoryQuery>(
    API,
    getProducerInvoiceHistoryQueryGql,
    {
      input,
    }
  );
};

export const getProducerCreditFn = async ({ queryKey }: QueryFnProps<ByIdInput>) => {
  const [, input] = queryKey;
  return await executeQuery<GetProducerCreditQuery>(API, getProducerCreditQueryGql, {
    input,
  });
};

export const createProducerFn = async (input: CreateProducerInput) => {
  return await executeQuery<CreateProducerMutation>(API, createProducerMutationGql, {
    input,
  });
};

export const updateProducerFn = async (input: UpdateProducerInput) => {
  return await executeQuery<UpdateProducerMutation>(API, updateProducerMutationGql, {
    input,
  });
};
