import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { getLogger } from 'utils/logging';

export const CUSTOM_ATTR_FIRST_NAME = 'custom:firstName';
export const CUSTOM_ATTR_LAST_NAME = 'custom:lastName';
export const CUSTOM_ATTR_PHONE_NUMBER = 'custom:phoneNumber';

export type CustomUserAttributes = Record<
  typeof CUSTOM_ATTR_FIRST_NAME | typeof CUSTOM_ATTR_LAST_NAME | typeof CUSTOM_ATTR_PHONE_NUMBER,
  string
>;

export type UserAttributes = {
  /** Cognito user pool user id (guaranteed globally unique) */
  sub: string;

  /** Whether the user has verified their email yet */
  email_verified: boolean;

  /** The user's claimed email address */
  email: string;
} & CustomUserAttributes;

export interface CurrentUserInfo {
  /** Cognito identity pool identity id */
  id: string;

  /** Cognito user pool username */
  username: string;

  /** Cognito user attributes */
  attributes: UserAttributes;
}

export type CurrentAuthenticatedUser = CognitoUser & {
  username: string;
  attributes: UserAttributes;
};

export const getUserCredentials = async () => await Auth.currentUserCredentials();

/**
 * Used for change password flow specifically
 * @returns the current authenticated user
 */
export const getCurrentAuthenticatedUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user as CurrentAuthenticatedUser;
};

/** Read the current authenticated user and their attributes */
export const getCurrentUserInfo = async () => {
  const userInfo = await Auth.currentUserInfo();
  return userInfo as CurrentUserInfo;
};

/** Refresh the current authenticated user's id token */
export const refreshCurrentSession = async () => {
  const log = getLogger('refreshCurrentSession');
  const user = await getCurrentAuthenticatedUser();
  const session = await Auth.currentSession();

  log.debug('refreshing id token');
  await new Promise((resolve, reject) => {
    user.refreshSession(session.getRefreshToken(), (err?: Error, session?: unknown) => {
      if (err) {
        log.debug('failed to refresh session', err);
        reject(err);
      } else {
        resolve(session);
      }
    });
  });
  log.debug('successfully refreshed id token');
};

export const signIn = async (username: string, password: string) =>
  await Auth.signIn({ username, password });

export const signUp = async (
  username: string,
  password: string,
  firstName: string,
  lastName: string,
  phoneNumber: string
) =>
  await Auth.signUp({
    username,
    password,
    attributes: {
      [CUSTOM_ATTR_FIRST_NAME]: firstName,
      [CUSTOM_ATTR_LAST_NAME]: lastName,
      [CUSTOM_ATTR_PHONE_NUMBER]: phoneNumber,
    },
  });

export const signOut = async () => await Auth.signOut();

export const resendCode = async (email: string) => await Auth.resendSignUp(email);

// takes an email and sends a code
export const forgotPassword = async (email: string) => await Auth.forgotPassword(email);

// leverages the code sent to customer's email and provides ability to reset the password
export const resetPassword = async (email: string, password: string, code: string) =>
  await Auth.forgotPasswordSubmit(email, code, password);

export const confirmSignUp = async (email: string, confirmationCode: string) =>
  await Auth.confirmSignUp(email, confirmationCode);

export const changePassword = async (
  currentUser: CognitoUser,
  oldPassword: string,
  newPassword: string
) => await Auth.changePassword(currentUser, oldPassword, newPassword);
