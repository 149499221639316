import { Grid } from '@chakra-ui/react';
import { Spinner, SpinnerSize } from 'components/Common/Spinner';
import { FC } from 'react';

interface IFullScreenSpinnerProps {}

const FullScreenSpinner: FC<IFullScreenSpinnerProps> = ({}) => {
  return (
    <Grid h="100vh" placeItems="center">
      <Spinner size={SpinnerSize.XLARGE} />
    </Grid>
  );
};
export default FullScreenSpinner;
