import { Flex, Stack } from '@chakra-ui/react';
import React from 'react';

export interface SpinnerProps {
  size?: SpinnerSize;
}

export enum SpinnerSize {
  XSMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xl',
}

const SpinnerSVG = () => {
  return (
    <svg
      id="e1zUgdRse5b1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 72 72"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision">
      <style>
        {`
                    #e1zUgdRse5b2_tr {animation: e1zUgdRse5b2_tr__tr 2000ms linear infinite normal forwards}
                    @keyframes e1zUgdRse5b2_tr__tr { 0% {transform: translate(36px,36px) rotate(0deg)} 20% {transform: translate(36px,36px) rotate(0deg);animation-timing-function: cubic-bezier(0.58,0.095,0.25,0.77)} 100% {transform: translate(36px,36px) rotate(360deg)}}
                    #e1zUgdRse5b3_tr {animation: e1zUgdRse5b3_tr__tr 2000ms linear infinite normal forwards}
                    @keyframes e1zUgdRse5b3_tr__tr { 0% {transform: translate(36px,36px) rotate(0deg)} 10% {transform: translate(36px,36px) rotate(0deg);animation-timing-function: cubic-bezier(0.58,0.095,0.25,0.77)} 100% {transform: translate(36px,36px) rotate(720deg)}}
                    #e1zUgdRse5b4_tr {animation: e1zUgdRse5b4_tr__tr 2000ms linear infinite normal forwards}
                    @keyframes e1zUgdRse5b4_tr__tr { 0% {transform: translate(36px,36px) rotate(0deg);animation-timing-function: cubic-bezier(0.565,0.235,0.3,0.66)} 100% {transform: translate(36px,36px) rotate(720deg)}}
                `}
      </style>
      <g id="e1zUgdRse5b2_tr" transform="translate(36,36) rotate(0)">
        <ellipse
          id="e1zUgdRse5b2"
          rx="23.82353"
          ry="23.82353"
          transform="translate(0,0)"
          fill="none"
          stroke="rgb(248,247,247)"
          strokeWidth="5"
          strokeDasharray="45,200"
        />
      </g>
      <g id="e1zUgdRse5b3_tr" transform="translate(36,36) rotate(0)">
        <ellipse
          id="e1zUgdRse5b3"
          rx="23.82353"
          ry="23.82353"
          transform="translate(0,0)"
          fill="none"
          stroke="rgb(229,232,231)"
          strokeWidth="5"
          strokeDasharray="45,200"
        />
      </g>
      <g id="e1zUgdRse5b4_tr" transform="translate(36,36) rotate(0)">
        <ellipse
          id="e1zUgdRse5b4"
          rx="23.82353"
          ry="23.82353"
          transform="translate(0,0)"
          fill="none"
          stroke="rgb(222,237,222)"
          strokeWidth="5"
          strokeDasharray="45,200"
        />
      </g>
    </svg>
  );
};

export const Spinner: React.FC<SpinnerProps> = ({ size = SpinnerSize.MEDIUM }) => {
  const spinerSizeRender = () => {
    switch (size) {
      case SpinnerSize.XLARGE:
        return '70px';
      case SpinnerSize.LARGE:
        return '60px';
      case SpinnerSize.MEDIUM:
        return '50px';
      case SpinnerSize.SMALL:
        return '25px';
      case SpinnerSize.XSMALL:
        return '20px';
      default:
        return '25px';
    }
  };

  return (
    <Flex justify="center" w="full">
      <Flex justify="center" flex="1">
        <Stack justify="center" align="center" width={spinerSizeRender()}>
          <SpinnerSVG />
        </Stack>
      </Flex>
    </Flex>
  );
};
