import { GraphQLOptions, GraphQLResult } from '@aws-amplify/api-graphql';
import { APIClass, graphqlOperation } from 'aws-amplify';
import { Kind, parse } from 'graphql';
import { inspect } from 'util';
import { getLogger } from 'utils/logging';

import { GraphQLError } from './errors';

type Operation = { name: string; type: string };
const queryToOperationCache = new Map<string, Operation>();

/** Parses a GraphQL query to determine the operation name/type. Uses a cache when possible. */
function parseOperation(gqlQuery: string): Operation {
  const cachedValue = queryToOperationCache.get(gqlQuery);
  if (cachedValue) {
    return cachedValue;
  }

  const def = parse(gqlQuery).definitions[0];
  if (def.kind !== Kind.OPERATION_DEFINITION || !def.name) {
    throw new Error(`Malformed query: ${gqlQuery}`);
  }
  const result = {
    name: def.name.value,
    type: String(def.operation),
  };
  queryToOperationCache.set(gqlQuery, result);
  return result;
}

/** Executes a GraphQL query in a type-safe way */
async function request<T>(api: APIClass, options: GraphQLOptions): Promise<GraphQLResult<T>> {
  const promise = api.graphql(options) as Promise<GraphQLResult<T>>;
  return await promise;
}

/** Removes resolver-level tracing data from a response or error object so it's not passed around downstream */
function stripTracing<T extends GraphQLResult<unknown> | GraphQLError>(thing: T): T {
  if (thing.extensions?.tracing) {
    delete thing.extensions.tracing;
  }
  return thing;
}

/** Executes a GraphQL query, traces the execution, and strips resolver-level tracing data */
export async function executeQuery<
  ResultType extends Record<string, unknown>,
  VariablesType extends Record<string, unknown> = Record<string, unknown>
>(api: APIClass, gqlQuery: string, variables: VariablesType): Promise<GraphQLResult<ResultType>> {
  const logger = getLogger('executeQuery');
  const { name: operationName } = parseOperation(gqlQuery);

  try {
    const response = await request<ResultType>(api, graphqlOperation(gqlQuery, variables));

    logger.debug(`GraphQL operation ${operationName} succeeded`);
    return stripTracing(response);
  } catch (e) {
    if (e instanceof Error) {
      // The request failed, but not with a GraphQL error
      logger.error(
        `GraphQL operation ${operationName} failed with a non-GraphQL error`,
        inspect(e, { depth: 8, breakLength: Infinity })
      );
      throw e;
    }

    if (!Object.hasOwn(e as object, 'errors')) {
      // Unrecognized POJO error (should not happen)
      logger.error(
        `GraphQL operation ${operationName} failed with an unrecognized POJO error`,
        inspect(e, { depth: 8, breakLength: Infinity })
      );
      throw e;
    }

    const graphqlError = stripTracing(e as GraphQLError);
    logger.debug(
      `GraphQL operation ${operationName} failed with an Apollo error`,
      inspect(graphqlError, { depth: 8, breakLength: Infinity })
    );

    throw e;
  }
}
