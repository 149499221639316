import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import components from './components';
import { fonts, fontSizes, textStyles } from './typography';

const overrides = {
  colors,
  fontSizes,
  textStyles,
  components,
  fonts,
};

export default extendTheme(overrides);
